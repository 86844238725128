<template>
  <div class="fineArts">
    <van-swipe
      class="my-swipe"
      :loop="true"
      :autoplay="3000"
      indicator-color="white"
    >
      <van-swipe-item v-for="item in banners" :key="item.Id">
        <img v-if="!item.video" :src="item.src" alt="" />
        <video v-else :src="item.src" controls></video>
      </van-swipe-item>
    </van-swipe>
    <van-row class="tabs">
      <van-col span="6" v-for="(t, i) in tabs" :key="i">
        <router-link :to="{ path: t.path }">
          <img :src="t.CategoryImg" :alt="t.value" srcset="" />
          <p>{{ t.CategoryName }}</p>
        </router-link>
      </van-col>
    </van-row>
    <div class="he_10"></div>
    <div v-if="experts.length > 0">
      <div class="title">
        <span class="name">专家团队</span>
        <router-link :to="'/H5/fineArts/expert'">
          <span class="more">查看更多<van-icon name="arrow" size="14" /></span>
        </router-link>
      </div>
      <ul class="expert">
        <li v-for="(e, i) in experts" :key="i">
          <router-link
            class="li"
            :to="{
              path: '/H5/art/authorDetails',
              query: {
                id: e.Id,
                type: 2,
              },
            }"
          >
            <img :src="e.HeadImg" alt="" srcset="" />
            <div class="intr">
              <p>{{ e.ArtistName }}</p>
              <p>{{ e.BriefIntroduce }}</p>
            </div>
          </router-link>
        </li>
      </ul>
      <div v-if="experts.length <= 0">
        <no-data></no-data>
      </div>
    </div>
    <div class="he_10"></div>
    <div v-if="list0.data.length > 0">
      <div class="title">
        <span class="name">{{ list0.CategoryName }}</span>
        <router-link
          class="li"
          :to="'/H5/fineArts/informationList?type=' + list0.Id"
        >
          <span class="more">查看更多<van-icon name="arrow" size="14" /></span>
        </router-link>
      </div>
      <ul class="dynamic">
        <li
          v-for="(item, i) in list0.data"
          :key="i"
          class="li"
          @click="handleTo(item)"
        >
          <img :src="item.CoverImg" alt="" />
          <div class="intr decimalPoint-1">{{ item.Title }}</div>
        </li>
      </ul>
    </div>
    <div class="he_10"></div>
    <div v-if="list1.data.length > 0">
      <div class="title">
        <span class="name">{{ list1.CategoryName }}</span>
        <router-link
          class="li"
          :to="'/H5/fineArts/informationList?type=' + list1.Id"
        >
          <span class="more">查看更多<van-icon name="arrow" size="14" /></span>
        </router-link>
      </div>
      <div class="communication">
        <ul>
          <li
            v-for="(item, i) in list1.data"
            :key="i"
            class="li"
            @click="handleTo(item)"
          >
            <img :src="item.CoverImg" alt="" />
            <div class="intr decimalPoint-1">{{ item.Title }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="he_10"></div>
    <div v-if="list2.data.length > 0">
      <div class="title">
        <span class="name">{{ list2.CategoryName }}</span>
        <router-link
          class="li"
          :to="'/H5/fineArts/informationList?type=' + list2.Id"
        >
          <span class="more">查看更多<van-icon name="arrow" size="14" /></span>
        </router-link>
      </div>
      <ul class="study">
        <li
          v-for="(item, i) in list2.data"
          :key="i"
          class="li"
          @click="handleTo(item)"
        >
          <img :src="item.CoverImg" alt="" />
          <div class="details">
            <p class="decimalPoint-2">{{ item.Title }}</p>
            <p>{{ item.CreateTime | dataYMD }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="he_10"></div>
    <div v-if="list3.data.length > 0">
      <div class="title">
        <span class="name">{{ list3.CategoryName }}</span>
        <router-link
          class="li"
          :to="'/H5/fineArts/informationList?type=' + list3.Id"
        >
          <span class="more">查看更多<van-icon name="arrow" size="14" /></span>
        </router-link>
      </div>
      <ul class="dynamic">
        <li
          v-for="(item, i) in list3.data"
          :key="i"
          class="li"
          @click="handleTo(item)"
        >
          <img :src="item.CoverImg" alt="" />
          <div class="intr decimalPoint-1">{{ item.Title }}</div>
        </li>
      </ul>
    </div>
    <div class="he_10"></div>
    <div v-if="list4.data.length > 0">
      <div class="title">
        <span class="name">{{ list4.CategoryName }}</span>
        <router-link
          class="li"
          :to="'/H5/fineArts/informationList?type=' + list4.Id"
        >
          <span class="more">查看更多<van-icon name="arrow" size="14" /></span>
        </router-link>
      </div>
      <div class="communication">
        <ul>
          <li
            v-for="(item, i) in list4.data"
            :key="i"
            class="li"
            @click="handleTo(item)"
          >
            <img :src="item.CoverImg" alt="" />
            <div class="intr decimalPoint-1">{{ item.Title }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="he_10"></div>
    <div>
      <div class="title">
        <span class="name">商务合作</span>
      </div>
      <div class="cooperation">
        <div class="intr">
          <p>
            普利美术馆是一座以收藏、研究、推广、展示
            近现代及当代艺术家作品为重点，并致力于审
            美教育与国际间文化交流的综合造型艺术博物 馆。
          </p>
          <div class="c_join">
            <router-link :to="'/shop/service/index'">
              加入
              <van-icon name="arrow" size="12" color="#fff" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>
<script>
import noData from "@/components/noData";
import { updateNewsReadCount, queryBannerList, queryArtist, queryArtGalleryCategory, queryArtGalleryNews } from "@/api/fineArts";
export default {
  name: "index",
  components: { noData },
  computed: {},
  watch: {},
  data () {
    return {
      tabs: [
        { CategoryImg: require("@/assets/images/icon_msgjs.png"), CategoryName: "美术馆介绍", path: "/H5/fineArts/details" },
        // { img: require("@/assets/images/icon_zldt.png"), value: "展览动态" },
        // { img: require("@/assets/images/icon_xsjl.png"), value: "学术交流" },
        // { img: require("@/assets/images/icon_ysyx.png"), value: "艺术研学" },
        // { img: require("@/assets/images/icon_crmx.png"), value: "成人美学" },
        // { img: require("@/assets/images/icon_yscz.png"), value: "艺术创作" },
        { CategoryImg: require("@/assets/images/icon_zjtd.png"), CategoryName: "专家团队", path: "/H5/fineArts/expert" },
      ],
      banners: [],
      experts: [],
      list0: { data: [] },
      list1: { data: [] },
      list2: { data: [] },
      list3: { data: [] },
      list4: { data: [] },
    };
  },
  mounted () {
    this.init()
  },
  methods: {
    handleTo (obj) {
      if (obj.JumpUrl) {
        window.open(obj.JumpUrl)
        this.postUpdateNewsReadCount(obj.Id)
      } else {
        let path = "/H5/informationListDetails";
        let query = {
          id: obj.Id,
        };
        this.$router.push({ path, query });
      }
    },
    //浏览量自动+1
    async postUpdateNewsReadCount (id) {
      let parm = "?Id=" + id
      const res = await updateNewsReadCount(parm);
    },
    async init () {
      let parm = "?category=4"
      const res = await queryBannerList(parm);
      if (res.success == true) {
        res.response.forEach(element => {
          var flieArr = element.ImgUrl.split('.')
          var suffix = flieArr[flieArr.length - 1]
          var videolist = ['mp4', 'm2v', 'mkv']
          var result = videolist.some((item) => {
            return item == suffix
          })
          this.banners.push({
            video: result,
            msrc: element.ImgUrl,
            src: element.ImgUrl,
            h: 375
          })
        });
      }
      let parm1 = {
        pageIndex: 1,
        pageSize: 3,
      }
      const res1 = await queryArtist(parm1);
      if (res1.success == true) {
        this.experts = res1.response.data;
      }
      const res2 = await queryArtGalleryCategory(0);
      if (res2.success == true) {
        res2.response.forEach((element, index) => {
          element.path = "/H5/fineArts/informationList?type=" + element.Id

          if (index <= 4) {
            this.postqueryArtGalleryNews(element, index);
          }
        });
        this.tabs = [...this.tabs, ...res2.response]
        // console.log(this.tabs, '')
      }
    },
    async postqueryArtGalleryNews (obj, index) {
      let size = 2
      if (index == 0 || index == 3) {
        size = 1
      }
      if (index == 2) {
        size = 3
      }
      let parm = {
        categoryId: obj.Id,
        type: 1,
        pageIndex: 1,
        pageSize: size,
      }
      const res = await queryArtGalleryNews(parm);
      if (res.success == true) {
        if (index == 0) {
          this.list0 = obj
          this.$set(this.list0, "data", res.response.data)
        }
        if (index == 1) {
          this.list1 = obj
          this.$set(this.list1, "data", res.response.data)
        }
        if (index == 2) {
          this.list2 = obj
          this.$set(this.list2, "data", res.response.data)
        }
        if (index == 3) {
          this.list3 = obj
          this.$set(this.list3, "data", res.response.data)
        }
        if (index == 4) {
          this.list4 = obj
          this.$set(this.list4, "data", res.response.data)
        }
      }
    },
  }
};
</script>
<style lang="less" scoped>
.fineArts {
  padding: 15px;
  min-height: 100vh;
  background-color: #fff;
  .my-swipe {
    margin: 0 0 10px;
    border-radius: 10px;
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      height: 160px;
      text-align: center;
      background-color: #000;
      img,
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
  .tabs {
    padding-bottom: 10px;
    .van-col {
      text-align: center;
    }
    img {
      width: 72px;
      height: 72px;
    }
    p {
      line-height: 1;
      color: #333;
      margin-bottom: 10px;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    line-height: 1;
    font-size: 12px;
    margin: 15px 0;
    .name {
      font-size: 16px;
      font-weight: 800;
    }
    .more {
      color: #333333;
      font-size: 12px;
      font-weight: 400;
      .van-icon {
        margin-left: 5px;
        vertical-align: bottom;
      }
    }
  }
  .expert {
    li {
      border-bottom: 1px solid #e6e6e6;
      &:last-child {
        border-bottom: none;
      }
    }
    .li {
      display: flex;
      // justify-content: space-between;
      margin: 15px 0 0;
      padding-bottom: 15px;
      img {
        flex: 0 0 80px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        // background: #e4e4e4;
        margin-right: 15px;
      }
      p {
        color: #333;
        line-height: 1.5;
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 800;
        }
        &:nth-child(2) {
          color: #6b666b;
          margin-top: 10px;
        }
      }
    }
  }
  .dynamic {
    li {
      position: relative;
      width: 100%;
      // height: 194px;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 15px;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
      }
      .intr {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        // height: 25px;
        line-height: 36px;
        font-weight: 500;
        font-size: 15px;
        color: #ffffff;
        padding: 0 15px;
        background: rgba(0, 0, 0, 0.5);
        p {
          color: #ffffff;
        }
      }
    }
  }
  .communication {
    width: 100%;
    height: 120px;
    overflow: hidden;
    margin-bottom: 15px;
    ul {
      display: -webkit-box;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      li {
        width: 180px;
        height: 120px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        display: inline-block;
        img {
          width: 100%;
          height: 100%;
        }
        .intr {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          // height: 25px;
          line-height: 36px;
          font-weight: 500;
          font-size: 15px;
          color: #ffffff;
          padding: 0 10px;
          background: rgba(0, 0, 0, 0.5);
          p {
            color: #ffffff;
          }
        }
      }
      li + li {
        margin-left: 15px;
      }
    }
  }
  .study {
    li {
      display: flex;
      margin: 15px 0 0;
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
      &:last-child {
        border-bottom: none;
      }
      img {
        flex: 0 0 146px;
        width: 146px;
        height: 84px;
        border-radius: 6px;
        background: #e4e4e4;
        margin-right: 15px;
      }
      .details {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
      }
      p {
        flex: 0 0 100%;
        color: #333;
        line-height: 1.5;
        &:nth-child(1) {
          font-weight: bold;
          font-size: 15px;
        }
        &:nth-child(2) {
          color: #999999;
          font-size: 13px;
        }
      }
    }
  }
  .cooperation {
    height: 203px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../../assets/images/bg2@2x.png");
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    .intr {
      width: 300px;
      min-height: 132px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 15px 20px;
      p {
        color: #ffffff;
        font-size: 13px;
        line-height: 1.5;
      }
      .c_join {
        font-size: 11px;
        color: #ffffff;
        width: 60px;
        height: 24px;
        line-height: 24px;
        background: #3a78f9;
        border-radius: 30px;
        text-align: center;
        margin: 0 auto;
      }
      i {
        vertical-align: middle;
      }
    }
  }
}

/deep/video::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>
